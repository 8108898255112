import { Component } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

/*#################################

Footer, incluye información de la empresa.

#################################*/

@Component({
    templateUrl: "footer.component.html",
    selector: "footer-component",
    styleUrls: ["footer.component.scss"]
})
export class FooterComponent {
    supportMail: string
    supportPhone: string

    config: typeof config

    showAdress: boolean = false

    constructor() {
        this.config = config

        this.supportMail = config.plataforma.info.soporte.mail
        this.supportPhone = config.plataforma.info.soporte.telefono ? config.plataforma.info.soporte.telefono : ""
    }
}
