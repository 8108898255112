import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Asignaturas, Asignatura, AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { last, first } from "rxjs/operators"

@Component({
    templateUrl: "home_saber11.component.html",
    styleUrls: ["home_saber11.component.scss"]
})
export class HomeSaber11Component extends MenuComponent implements OnInit {
    menuItemsCol1: MenuItemBoxData[] = []
    menuItemsCol2: MenuItemBoxData[] = []
    menuItemsCol3: MenuItemBoxData[] = []
    asignaturas: AsignaturaWithConfig[]

    constructor(protected store: Store<State>, protected loggedLayoutService: LoggedLayoutService) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    async setMenuItems() {
        const asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.pipe(first()).toPromise()
        this.asignaturas = asignaturasByEvaluacionTipo["saber11"]

        this.menuItemsCol1 = [
            {
                route: "/saber11/generar_simulacro/",
                params: { tipo_instrumento: "simulacro saber11" },
                label: "Simulacros Saber 11",
                text: "Comparte simulacros con tus estudiantes de una lista de simulacros seleccionados.",
                linkText: "Comenzar",
                icon: "hacer-prueba"
            },
            {
                route: "/saber11/simulacros_compartidos/",
                params: { tipo_instrumento: "simulacro saber11" },
                label: "Historial de simulacros",
                text: "Lista de simulacros que has compartido.",
                linkText: "Ver historial",
                icon: "guia"
            },
            {
                route: "/saber11/estadisticas_multiples/",
                params: {
                    tipo_instrumento: "simulacro saber11"
                },
                label: "Estadísticas global y por asignatura",
                text: "Estadísticas generadas a partir de los resultados obtenidos en los simulacros.",
                linkText: "Ver estadísticas",
                icon: "estadistica-o"
            } /*,
        {
            route: '/saber11/generar_simulacro/',
            params: {tipo_instrumento: 'saber11'},
            label: "Progreso de tus estudiantes",
            text: "Observa la evolución en el tiempo de los resultados obtenidos por los estudiantes.",
            linkText: "Ver progreso",
            icon: "estadistica-o"
        }*/
        ]

        this.menuItemsCol2 = [
            {
                route: "/saber11/generar_simulacro/" + asignaturasByEvaluacionTipo["saber11"][0].id,
                params: { tipo_instrumento: "saber11" },
                label: "Simulacro Saber 11 por asignatura",
                text: "Genera simulacros por asignatura y compártelos con tus estudiantes. Con opciones personalizables.",
                linkText: "Comenzar",
                icon: "hacer-prueba"
            },
            {
                route: "/saber11/compartidas",
                params: { tipo_instrumento: "saber11", asignatura_id: asignaturasByEvaluacionTipo["saber11"][0].id },
                label: "Historial de simulacros por asignatura",
                text: "Lista de todos los simulacros compartidos, no compartidos y presenciales.",
                linkText: "Ver historial",
                icon: "guia"
            },
            {
                route: "/saber11/progreso/",
                params: { evaluacion_tipo: "saber11" },
                label: "Progreso de tus estudiantes por asignatura",
                text: "Observa la evolución en el tiempo de los resultados obtenidos por los estudiantes.",
                linkText: "Ver progreso",
                icon: "estadistica-o"
            },
            {
                route: "/saber11/estadisticas/",
                params: { tipo_instrumento: "saber11", asignatura_id: asignaturasByEvaluacionTipo["saber11"][0].id },
                label: "Estadísticas por asignatura",
                text: "Estadísticas generadas a partir de los resultados obtenidos en los simulacros por asignatura.",
                linkText: "Ver estadísticas",
                icon: "estadistica-o"
            },
            /*             {
                route: "/instrumentos/subir_prueba_propia",
                params: { tipo_instrumento: "saber11" },
                label: "Subir pruebas propias",
                text: "Sube tus propias pruebas y compártelas con tus estudiantes.",
                linkText: "Comenzar",
                icon: "hacer-prueba",
                cornerRibbon: {
                    text: "Beta"
                }
            }, */
            {
                route: "/saber11/libro",
                params: { tipo_instrumento: "saber11" },
                label: "Libro de Clases",
                text: "Aquí podrás encontrar información de tus alumnos y las evaluaciones que han rendido.",
                linkText: "Ver libro",
                icon: "libro-o"
            }
        ]

        this.menuItemsCol3 = [
            {
                route: "/evaluacion_instancias/subir",
                label: "Subir resultados",
                text: "Sube resultados de simulacros a partir de una hoja de respuesta o una planilla CSV.",
                linkText: "Subir resultados",
                icon: "correccion-imagen"
            }
        ]
    }
}
