<footer title="Informacion de la empresa" class="print-hide">
    <div class="txt-company-footer">
        <div class="centerColumn">
            <p>Encuéntranos también en:</p>
            <div class="socialMedia">
                <div class="t_row">
                    <div class="t_cell">
                        <a href="{{ config.plataforma.info.social.instagram }}" target="_blank" title="Instagram">
                            <fa name="instagram"></fa>
                        </a>
                    </div>
                    <div class="t_cell">
                        <a href="{{ config.plataforma.info.social.youtube }}" target="_blank" title="Youtube">
                            <fa name="youtube"></fa>
                        </a>
                    </div>
                    <div class="t_cell">
                        <a href="{{ config.plataforma.info.social.twitch }}" target="_blank" title="Twitch">
                            <fa name="twitch"></fa>
                        </a>
                    </div>
                </div>
            </div>
            <p class="direccion" *ngIf="showAdress">
                <ogr-icon name="home-o" align="top" class="icono"></ogr-icon>
                Calle 93 # 13 42 Of. 306, Bogotá, Colombia.
            </p>
        </div>
        <div class="rightColumn">
            <div class="speechbubble">
                <p>Contáctanos:</p>
                <p class="email">
                    <em>{{ supportMail }}</em>
                </p>
                <fa name="envelope-o" class="icono"></fa>
            </div>
            <div class="speechbubble">
                <p>Llámanos o escríbenos por whatsapp:</p>
                <p class="email">
                    <em [innerHTML]="supportPhone"></em>
                </p>
                <p><fa name="whatsapp" class="icono"></fa></p>
            </div>
        </div>
        <div class="leftColumn">
            <a href="http://ogr.cl/">
                <img
                    class="footer-logo"
                    src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/logo_ogr_light.png"
                    alt="{{ config.plataforma.info.companyName }}"
                />
            </a>
            <p>
                Todos los Derechos Reservados a "Open Green Road Colombia S.A.S."
                <br />
                © 2018 Copyright
            </p>
            <hr />
            <!-- <a style="color: white" routerLink="/ayudas">Centro de ayuda</a> -->
        </div>
    </div>
</footer>
